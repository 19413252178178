import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Leaderboard from "../components/Leaderboard";
import ReadTopics from "../components/ReadTopics";
import theme from "../ui/theme";
import ClickTable from "../components/ClickTable";

const SpotlightHeading = styled.div`
  font-family: "Montserrat", "Open Sans", arial, sans-serif;
  overflow: hidden;
  .container {
    margin: 50px auto;
    max-width: 860px;
    padding: 0 30px;
    text-align: center;
    @media all and (max-width: ${theme.breakpoints.md}) {
      margin: 0;
      padding: 0 20px;
    }
  }
  h1 {
    font-size: 45px;
    span {
      color: #3ac0a4;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 25px;
      margin: 0 0 1.5rem;
    }
  }
  h2 {
    color: #fff;
    font-size: 30px;
    padding: 0;
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 16px;
    }
  }
  p {
    font-size: 20px;
    margin: 0;
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 16px;
    }
    strong {
      color: #3ac0a4;
    }
  }
`;

const NoMatchOffers = ({ data }) => (
  <Layout templateName="sorry wide">
    <Seo
      title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
      description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      image={
        data.wordpressPage.featured_media &&
        data.wordpressPage.featured_media.localFile
          ? `${
              process.env.NODE_ENV === "production"
                ? data.site.siteMetadata.baseUrl
                : typeof window !== "undefined" && window.location.host
            }${
              data.wordpressPage.featured_media.localFile.childImageSharp
                .original.src
            }`
          : ""
      }
      canonical={data.wordpressPage.yoast_meta.yoast_wpseo_canonical}
    />
    <SpotlightHeading>
      <div className="container">
        <h1>
          Sorry! We could not find any open positions matching your criteria at
          this time.
        </h1>
        <p>
          Nurses who have a <strong>secondary specialty</strong>, have{" "}
          <strong>more than 1-2 years of experience</strong>, and{" "}
          <strong>are RNs</strong> have a higher chance of being matched. Travel
          jobs are added every week, so check back often!
        </p>
      </div>
    </SpotlightHeading>
    <ClickTable
      instance="67daff21800afff177402ecd"
      tracking_id="no_match_ct"
      id="no_match_ct"
    />
    <Leaderboard
      heading="Thinking about furthering your nursing education?"
      subheading="Compare schools now. Takes 1 min!"
      btnColor="tertiary"
      btnText="Click To See Online Nursing Schools"
      btnLink="https://nurse.org/school-programs/#context/api/listings/prefilter"
    />
    {data.wordpressPage.acf && (
      <ReadTopics topics={data.wordpressPage.acf.topics} />
    )}
  </Layout>
);

export default NoMatchOffers;

export const query = graphql`
  query NoMatchOffersPageQuery {
    site {
      siteMetadata {
        baseUrl
      }
    }
    wordpressPage(wordpress_id: { eq: 6112 }) {
      content
      featured_media {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        topics {
          heading
          content
          link
          image {
            alt_text
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
